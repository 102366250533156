body
{
    margin: 0;
    scroll-behavior: smooth;
    background-color: #101010;
    height: 100vh;
    width: 100%;
}

@font-face {
    font-family: "custom";
    src: url(https://fonts.gstatic.com/s/googlesans/v29/4UaGrENHsxJlGDuGo1OIlL3Owp5eKQtG.woff2) format("woff2");
}